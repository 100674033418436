import React, { useState, useEffect } from "react";
import Layout from "../../components/shared-layout";
import AcademicExternal from "../../components/student-components/academic-external";
import AcademicOverview from "../../components/student-components/academic-overview";
import BuildIcon from '@material-ui/icons/Build';
import errorImg from '../../images/404.jpg';

const ErrorMessagePage = ({ SelectedStudentId, studentList, semester, image }) => {
  const [acadamic, setAcademics] = useState(true);

  useEffect(() => {
  });

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={false}
          showYearSemTerm={false}
          showClassCode={false}
          showDomain={false}
          showStaffList={false}
          showFilter={false}
          showDateRange={false}
          showYearRange={false}
          showNewsArea={true}
        >
          <div className="col-span-4 mt-2 flex flex-col">
            {/* <AcademicOverview /> */}
            {/* <span className="text-center w-full p-10 text-barPrimary font-bold text-4xl">Sorry! This page you visited does not exist !!</span> */}
            <div className="w-full">
              <img src={errorImg} className="w-2/3" style={{ margin: '40px auto' }} />
            </div>
          </div>
        </Layout>
      }
    </>
  );
}

export default ErrorMessagePage;